import React from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { ButtonBase, IconButton, Theme, Tooltip, Typography } from '@material-ui/core'
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown'
import DisconnectIcon from '@material-ui/icons/Close'
import { useBoolean } from 'hooks/useBoolean'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import ProjectEmoji from 'features/project/projectEmoji'

const BORDER_RADIUS = 4

const useProjectBadgeStyles = makeStyles((theme: Theme) => ({
  projectBadgeContainer: {
    alignItems: 'center',
    backgroundColor: theme.threadPalette.lighterGray,
    borderRadius: BORDER_RADIUS,
    color: theme.threadPalette.brownishGray,
    display: 'inline-flex',
    minWidth: 0,
    maxWidth: 250,
    paddingRight: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  folderIcon: {
    fontSize: 24,
    marginLeft: 2,
  },
  emojiIcon: {
    fontSize: 20,
    marginLeft: 1,
  },
  arrowDownIconContainer: {
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    color: theme.threadPalette.white,
    display: 'inline-flex',
    paddingInline: 3,
  },
  arrowDownIcon: {
    fontSize: 24,
  },
  projectNameAndCount: {
    alignItems: 'center',
    display: 'inline-flex',
    marginLeft: 6,
    minWidth: 0,
  },
  projectName: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  threadCountSeparator: {
    color: theme.threadPalette.lightSoftGray,
    marginLeft: 4,
    marginRight: 6,
  },
  threadCount: {
    fontSize: 12,
    fontWeight: 300,
  },
  disconnectProjectIconContainer: {
    color: theme.threadPalette.brownishGray,
    '&:hover': {
      backgroundColor: theme.threadPalette.white,
      color: theme.palette.error.contrastText,
    },
  },
  disconnectProjectIcon: {
    fontSize: 12,
  },
}))

interface IProjectBadgeProps {
  name: string
  emoji?: string
  threadCount?: number
  className?: string
  onClick?(): void
  onDisconnect?(): void
}
const ProjectBadge = React.forwardRef<HTMLDivElement, IProjectBadgeProps>(
  ({ name, threadCount, emoji, className, onClick, onDisconnect }, ref) => {
    const classes = useProjectBadgeStyles()
    const { textRef: projectNameRef, hasEllipsis: showTooltip } = useTextInEllipsis()

    const { state: isHovering, setTrue: setStartedHovering, setFalse: setStoppedHovering } = useBoolean(false)
    const iconOnNotHovering = (
      <ProjectEmoji emoji={emoji} className={classes.folderIcon} emojiClassName={classes.emojiIcon} />
    )
    const iconOnHovering = (
      <div className={classes.arrowDownIconContainer}>
        <ArrowDownIcon className={classes.arrowDownIcon} />
      </div>
    )

    const icon = isHovering ? iconOnHovering : iconOnNotHovering

    const onDisconnectProject = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation()
      event.preventDefault()
      onDisconnect?.()
    }
    return (
      <ButtonBase
        ref={ref}
        component="div"
        className={classNames(classes.projectBadgeContainer, className)}
        onClick={onClick}
        onMouseEnter={setStartedHovering}
        onMouseLeave={setStoppedHovering}
      >
        {icon}
        <div className={classes.projectNameAndCount}>
          <Tooltip title={showTooltip ? name : ''}>
            <Typography ref={projectNameRef} className={classes.projectName}>
              {name}
            </Typography>
          </Tooltip>
          <Typography className={classes.threadCountSeparator}>|</Typography>
          <Typography className={classes.threadCount}>{threadCount}</Typography>
        </div>
        {isHovering && (
          <IconButton
            edge="end"
            size="small"
            onClick={onDisconnectProject}
            classes={{ root: classes.disconnectProjectIconContainer }}
          >
            <DisconnectIcon className={classes.disconnectProjectIcon} />
          </IconButton>
        )}
      </ButtonBase>
    )
  }
)

export default ProjectBadge
