import React from 'react'
import { Button, List, Theme, Typography, useTheme, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Spacer from 'component/layoutUtils/spacer'
import ThreadCard from './threadCard'
import { IProject } from 'model/project'
import AppLoading from 'component/appLoading'
import { useNewThreadModalContext } from 'component/newThreadModal/newThreadModal'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { useOpenThreadUseCase } from 'usecases/openThread'
import { useProject } from 'hooks/useProjects/useProject'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'actions/snackbar'
import { useThreadSharingSettingsCacheActions } from 'hooks/useSharingGroup/useThreadSharingSettingsCacheActions'
import { useOpenProjectDetailsUseCase } from 'usecases/openProjectDetails'
import { useBoolean } from 'hooks/useBoolean'
import classNames from 'classnames'
import ProjectEmoji from 'features/project/projectEmoji'

const useProjectDetailsStyles = makeStyles((theme: Theme) => ({
  projectHeaderContainer: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    marginBottom: 8,
    width: '100%',
  },
  projectNameContainer: {
    alignItems: 'center',
    display: 'inline-flex',
  },
  folderIcon: {
    fontSize: 26,
  },
  emojiIcon: {
    fontSize: 28,
  },
  projectName: {
    fontSize: 22,
    width: 'inherit',
  },
  openProjectIconContainer: {
    borderRadius: 4,
    color: theme.threadPalette.white,
    marginBottom: 2,
    marginLeft: 8,
    '&.hovering': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  openProjectIcon: {
    color: 'inherit',
    fontSize: 16,
  },
  threadsHeaderContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  threadsSubtitle: {
    color: theme.palette.error.main,
    fontSize: 12,
    fontWeight: 'bold',
  },
  newThreadButton: {
    color: 'inherit',
    fontSize: 12,
  },
}))

interface IProjectDetailsProps {
  project: IProject
  currentThreadId: string
  onDisconnectThread(threadId: string): void
  onClose?(): void
}

function ProjectDetails({ project, onDisconnectThread, onClose, currentThreadId }: IProjectDetailsProps) {
  const classes = useProjectDetailsStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const { openModal: openNewThreadModal, setProject: setNewThreadProject } = useNewThreadModalContext()
  const { openThreadByIdUseCase } = useOpenThreadUseCase()

  const threadSharingSettingsCacheActions = useThreadSharingSettingsCacheActions()
  const { openProjectDetailsUseCase } = useOpenProjectDetailsUseCase()

  const {
    state: isHoveringProjectName,
    setTrue: startHoveringProjectName,
    setFalse: stopHoveringProjectName,
  } = useBoolean(false)

  const onProjectDetailsLoadError = () => {
    dispatch(showSnackbar({ message: 'Failed to load project details' }))
    onClose?.()
  }

  const { project: projectDetails, isLoading: isLoadingProjectDetails } = useProject({
    projectId: project?.id,
    onError: onProjectDetailsLoadError,
  })

  const onThreadClick = (threadId: string) => {
    openThreadByIdUseCase({ threadId })
  }

  const onNewThreadClick = React.useCallback(() => {
    setNewThreadProject(project)
    openNewThreadModal()
  }, [setNewThreadProject, openNewThreadModal, project])

  return (
    <>
      <div
        className={classes.projectHeaderContainer}
        onMouseOver={startHoveringProjectName}
        onMouseOut={stopHoveringProjectName}
        onClick={() => openProjectDetailsUseCase(project.id)}
      >
        <ProjectEmoji emoji={project.emoji} className={classes.folderIcon} emojiClassName={classes.emojiIcon} />
        <Spacer width={8} />
        <div className={classes.projectNameContainer}>
          <Typography className={classes.projectName}>
            {project.name}
            <IconButton
              size="small"
              className={classNames(classes.openProjectIconContainer, { hovering: isHoveringProjectName })}
            >
              <OpenInNewIcon className={classes.openProjectIcon} />
            </IconButton>
          </Typography>
        </div>
      </div>
      <div className={classes.threadsHeaderContainer}>
        <Typography className={classes.threadsSubtitle}>Threads connected to this project</Typography>
        <Button onClick={onNewThreadClick} className={classes.newThreadButton}>
          + New thread
        </Button>
      </div>
      {isLoadingProjectDetails && (
        <>
          <AppLoading visible small fill={theme.threadPalette.white} padding={8} legHeight={24} legWidth={8} />
          <Spacer height={8} />
        </>
      )}
      <List disablePadding>
        {projectDetails?.threads?.map(projectThread => (
          <React.Fragment key={projectThread.id}>
            <ThreadCard
              title={projectThread.title}
              titleAlias={projectThread.titleAlias}
              messagesCount={projectThread.messageCount}
              followers={projectThread.followers}
              onClick={() => onThreadClick(projectThread.id)}
              onUnlink={() => onDisconnectThread(projectThread.id)}
              onHover={() => threadSharingSettingsCacheActions.prefetchData(projectThread.id)}
            />
            <Spacer height={10} />
          </React.Fragment>
        ))}
      </List>
    </>
  )
}

export default ProjectDetails
