import { IProject } from 'model/project'
import * as React from 'react'
import { Button, Theme, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import useDialog from 'component/dialogAnchoredOnComponent/useDialog'
import { ProjectMenuPopover } from './projectMenuPopover'
import { ProjectThreadsDropdown } from './projectThreadsDropdown/projectThreadsDropdown'
import classNames from 'classnames'
import { useTextInEllipsis } from 'hooks/useTextInEllipsis'
import ProjectEmoji from 'features/project/projectEmoji'

export const useProjectItemStyles = makeStyles((theme: Theme) => {
  return {
    projectItemRoot: {
      display: 'flex',
      flexDirection: 'column',
    },
    projectItemButton: {
      alignItems: 'center',
      color: theme.threadPalette.white,
      display: 'flex',
      flexDirection: 'row',
      height: '46px',
      padding: '4px 10px 4px 10px',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.threadPalette.onFocusGray,
      },
    },
    projectItemThreadsExpanded: {
      height: '30px',
    },
    projectItemRootFocus: {
      backgroundColor: theme.threadPalette.onFocusGray,
    },
    folderIcon: {
      fill: theme.threadPalette.white,
      height: 24,
      marginInline: 2,
      width: 24,
    },
    emojiIcon: {
      fontSize: '1.5rem',
      marginRight: 4,
    },
    projectName: {
      display: '-webkit-box',
      fontSize: '14px',
      marginLeft: '6px',
      marginRight: '12px',
      maxHeight: '42px',
      overflow: 'hidden',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      width: '100%',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    projectNameThreadsExpanded: {
      '-webkit-line-clamp': 1,
    },
    threadCount: {
      fontSize: '10px',
    },
    menuIcon: {
      color: theme.threadPalette.lighterGray,
      fontSize: '22px',
    },
    projectActionsWrapper: {
      display: 'flex',
      justifyContent: 'center',
      width: '22px',
    },
    loadingItem: {
      '&.MuiSkeleton-root': {
        backgroundColor: '#3b4759',
      },
    },
    folderLoadingIcon: {
      borderRadius: '4px',
    },
    threadNameLoading: {
      height: '22px',
    },
  }
})

interface IProjectItemProps {
  project: IProject
  onNewThreadInProject(project: IProject): void
}

export type TProjectMenuOption = 'newThread'

export const ProjectItem = ({ project, onNewThreadInProject }: IProjectItemProps) => {
  const classes = useProjectItemStyles()

  const projectMenuRef = React.useRef<HTMLDivElement | null>(null)
  const [isHovered, setIsHovered] = React.useState<boolean>(false)

  const [isThreadsExpanded, setIsThreadsExpanded] = React.useState<boolean>(false)

  const {
    isOpen: isProjectMenuOpen,
    handleCloseDialog: closeProjectMenuDialog,
    dialogAnchorElement: projectMenuAnchorElement,
    setDialogAnchorRef: setProjectMenuAnchorElement,
    openDialog: openProjectMenuDialog,
  } = useDialog()

  const { textRef: projectNameRef, isOverflowingVertically: showTooltip } = useTextInEllipsis()

  const onMenuItemSelect = React.useCallback(
    (menuOption: TProjectMenuOption) => {
      switch (menuOption) {
        case 'newThread': {
          onNewThreadInProject(project)
        }
      }
      closeProjectMenuDialog()
    },
    [onNewThreadInProject, project, closeProjectMenuDialog]
  )
  const onMenuClick = React.useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      setProjectMenuAnchorElement(projectMenuRef.current)
      openProjectMenuDialog()
    },
    [projectMenuRef]
  )

  const onMenuClose = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    closeProjectMenuDialog()
  }

  const onProjectClick = React.useCallback(() => {
    if (project.threadCount === 0) return
    setIsThreadsExpanded(prev => !prev)
  }, [project.threadCount])

  React.useEffect(() => {
    if (project.threadCount === 0) setIsThreadsExpanded(false)
  }, [project.threadCount])

  const projectItemButtonClassName = classNames(classes.projectItemButton, {
    [classes.projectItemThreadsExpanded]: isThreadsExpanded,
  })
  const projectNameClassName = classNames(classes.projectName, {
    [classes.projectNameThreadsExpanded]: isThreadsExpanded,
  })

  return (
    <div className={classes.projectItemRoot}>
      <Button
        classes={{ root: projectItemButtonClassName }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseOver={() => setIsHovered(true)}
        disableRipple
        focusVisibleClassName={classes.projectItemRootFocus}
        onClick={onProjectClick}
      >
        <ProjectEmoji emoji={project.emoji} className={classes.folderIcon} emojiClassName={classes.emojiIcon} />
        <Tooltip title={showTooltip ? project.name : ''} arrow={true} aria-label="project name">
          <Typography ref={projectNameRef} className={projectNameClassName}>
            {project.name}
          </Typography>
        </Tooltip>
        <div className={classes.projectActionsWrapper} ref={projectMenuRef}>
          {isHovered || isThreadsExpanded ? (
            <MoreHorizIcon className={classes.menuIcon} onClick={onMenuClick} />
          ) : (
            <Typography className={classes.threadCount}>{project.threadCount}</Typography>
          )}
        </div>
      </Button>
      <ProjectMenuPopover
        isOpen={isProjectMenuOpen}
        onMenuItemSelect={onMenuItemSelect}
        onClose={onMenuClose}
        anchorEl={projectMenuAnchorElement}
      />
      {isThreadsExpanded && <ProjectThreadsDropdown project={project} />}
    </div>
  )
}
