import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import CustomChip from 'shared/components/chip'
import ProjectEmoji from './projectEmoji'

const useProjectChipStyles = makeStyles((theme: Theme) => ({
  chipRoot: {
    backgroundColor: theme.threadPalette.darkBlue,
    paddingLeft: 4,
  },
  folderIcon: {
    height: 10,
    width: 10,
    fill: theme.threadPalette.white,
  },
  emojiIcon: {
    fontSize: '1.5rem !important',
    paddingTop: 1,
  },
}))

interface IProjectChipProps {
  name: string
  emoji: string
  onRemove?(): void
}

const ProjectChip = ({ name, emoji, onRemove }: IProjectChipProps) => {
  const classes = useProjectChipStyles()
  return (
    <CustomChip
      className={classes.chipRoot}
      avatar={<ProjectEmoji emoji={emoji} className={classes.folderIcon} emojiClassName={classes.emojiIcon} />}
      label={name}
      onDelete={onRemove}
    />
  )
}

export default ProjectChip
